/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@use "@angular/material" as mat;
@import "~bootstrap/scss/bootstrap";
@import "./variables";
@include mat.core();
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Karla", sans-serif;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

:focus:not(:focus-visible) {
  box-shadow: none;
}

.cursor-pointer {
  cursor: pointer;
}
.fw-500 {
  font-weight: 500;
}
.fs-7 {
  font-size: 0.875rem;
}

.display-panel {
  height: 100%;
}
.mt-115 {
  margin-top: 115px;
}
.fs-8 {
  font-size: 0.6rem;
}

.img-bg {
  background-image: url("/assets/images/bg.png");
  background-size: cover;
  background-position: bottom right;
}

.teal-btn {
  border-radius: 76px;
  background: transparent;
  font-family: "Wonky";
  border: none;
  position: relative;
  padding-top: 7px;
  z-index: 0;
  color: black;

  &:focus {
    border: 2px solid $dark-teal !important;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }

  &::before {
    content: "";
    height: 100%;
    width: 100%;
    background: $teal;
    border-radius: 76px;
    position: absolute;
    z-index: -1;
    left: -1px;
    top: 5px;
  }

  &::after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 76px;
    border: 2px solid #000;
  }

  &:disabled {
    cursor: not-allowed;
    color: #515156;
    &:hover::before {
      top: 3px;
    }

    &::before {
      background: $light-gray;
    }
    &::after {
      border: 2px solid #515156;
    }
  }

  &:hover::before {
    top: 7px;
  }
}

.medium-teal-btn {
  width: 450px;
  height: 50px;
  background: $medium-teal;
  border-radius: 76px;
  font-family: "Wonky";
  border: none;
  padding-top: 10px;
  color: black;

  &:hover {
    background: $teal;
  }
}

.custom-slider {
  width: 400px;
  .ngx-slider {
    &:focus,
    &:hover {
      outline: none;
      .ngx-slider-pointer {
        border: 2px solid black;
        width: 20px;
        height: 20px;
        top: -16px;
      }
    }
    z-index: 0;
    .ngx-slider-bar-wrapper {
      padding-top: 0;
      margin-top: -18px;
    }

    .ngx-slider-pointer {
      width: 16px;
      height: 16px;
      top: -14px;
      background-color: black;
      outline: none;

      &::after {
        display: none;
      }
    }

    .ngx-slider-bar {
      height: 10px;
      background: transparent;
      z-index: 0;
      position: relative;

      &::after {
        border: 1px solid black;
        height: 100%;
        width: 100%;
        border-radius: 49px;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .ngx-slider-selection-bar {
      visibility: visible !important;
    }

    .ngx-slider-selection::before {
      content: "";
      height: 100%;
      width: 100%;
      border-radius: 49px;
      background: $teal;
      z-index: -1;
      position: absolute;
      top: 2px;
      left: 2px;
    }

    &[disabled] {
      .ngx-slider-selection::before {
        display: none;
      }

      .ngx-slider-model-value {
        color: $dark-gray;
      }

      .ngx-slider-pointer {
        background-color: $dark-gray;
      }
    }

    .ngx-slider-model-value {
      top: 3px;
      font-weight: bold;
      color: black;
      font-family: "Karla";
      font-weight: 800;
      font-size: 20px;
      line-height: 23.38px;
      .upper-label {
        position: absolute;
        top: -40px;
        &.minor {
          font-family: "Karla";
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          font-style: italic;
        }
      }
    }
  }
}

.modal-backdrop.show {
  opacity: 0.2;
}
.notepad {
  overflow-y: hidden;
  height: 100%;
  transform: translateY(100%);
  animation-name: modal;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;

  @keyframes modal {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  .modal-dialog {
    height: 100%;
  }

  .modal-content {
    height: 95%;
    min-width: 1000px;
    display: flex;
    justify-content: flex-start;
    background-image: url("/assets/images/modal-bg.png");
    background-size: cover;
    background-color: transparent;
    font-family: "Karla";
    position: relative;
    right: 13%;
    margin-top: 80px;

    &.close {
      animation-name: modal-close;
      animation-duration: 0.5s;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      transform: translateY(110%);

      @keyframes modal-close {
        0% {
          transform: translateY(80px);
          margin-top: 80px;
        }
        100% {
          transform: translateY(110%);
          margin-top: 80px;
        }
      }
    }
  }
}

.wonky {
  font-family: "Wonky";
}

.display-none {
  display: none;
}

@font-face {
  font-family: "Karla";
  src:
    local("Karla"),
    url(./assets/fonts/Karla-Regular.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Karla";
  src:
    local("Karla"),
    url(./assets/fonts/Karla-Bold.ttf) format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Karla";
  src:
    local("Karla"),
    url(./assets/fonts/Karla-Italic.ttf) format("truetype");
  font-style: italic;
}
@font-face {
  font-family: "Wonky";
  src:
    local("Wonky"),
    url(./assets/fonts/WonkyFont-WowThatsWonky.otf) format("opentype");
  font-weight: normal;
}
.covered::after {
  content: "";
  position: absolute;
  top: 41px;
  left: 4px;
  width: calc(100% - 8px);
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
  z-index: 100;
}

.firebase-emulator-warning {
  display: none;
}

@media print {
  .no-print {
    display: none !important;
  }

  button {
    display: none !important;
  }
}
