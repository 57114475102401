$gray: #eeeeee;
$light-gray: #e5e5e5;
$dark-gray: #515156;
$lighter-gray: #d8d8dd;
$red: #c01409;
$dark-red: #a40000;
$green: #0b7507;
$dark-green: #004f12;
$light-green: #006917;
$peach: #fb9586;
$yellow: #ffd952;
$light-yellow: #ffeba5;
$medium-teal: #b4f1f6;
$medium-gray: #adaeb4;
$dark-teal: #002f33;
$teal: #14b1bd;
$almost-white: #f1f1f3;
$lightest-teal: #dffcff;
